import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

const ContactOne = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSuccess(true);

    emailjs
      .sendForm('service_hjqzc87', 'template_clemtx2', form.current, {
        publicKey: 'HC2je1M_WgxRsMcHY',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <section className="contact_form_area contact_us">
      <div className="contact_form_width">
        <h1 className="contact_map_title text-center">
          O nosso serviço vem com satisfação garantida.
        </h1>
        <div id="map">
          <iframe
            title="template google map"
            src="https://maps.google.com/maps?q=estoril%20office%20center&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="contact_form_width contact-right">
        <div className="hero-title-with-shape">
          <h4 className="heading_with_border">Receba um orçamento gratuito</h4>
          <h1>Preencha o formulário</h1>
        </div>
        <div className="contact-right contact-right-style-2 responsive_mt">
              {!isSuccess && (<form ref={form} onSubmit={sendEmail}>
                <input
                    className="half_width input_m_right"
                    type="text"
                    name="name"
                    placeholder="Nome"
                  />
                  <input
                    className="half_width"
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                  <input type="tel" name="tel" placeholder="Telemóvel" />
                  <textarea
                    name="content"
                    id="content"
                    cols="20"
                    rows="5"
                    placeholder="A sua Mensagem"
                  ></textarea>
                  <input className="btn-yellow" type="submit" value="Send" />
              </form>)}

              {isSuccess && <p>Mensagem enviada com sucesso!</p>}
            </div>
      </div>
    </section>
  );
};

export default ContactOne;
